<template>
  <v-container fluid pa-3 style="min-height: 100vh;">
    <v-row>
      <v-col cols="12" sm="9" order="1" order-sm="1">
        <v-col cols="12" class="py-0">
          <h1 class="font-weight-light mb-0">Recent Transactions</h1>
          <span class="overline"
            >Below are the debits and credits company account transactions</span
          >
        </v-col>

        <v-card class="py-2 pa-0 mb-5">
          <v-data-table
            :headers="headers"
            :items="getCompanyTransactions"
            item-key="id"
            dark
          >
            <template v-slot:item="{ item }">
              <tr @click="displayTransactionContextMenu(item)">
                <td>{{ item.date | exactDay }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.status }}</td>
                <td>{{ item.gross }}</td>
                <td>{{ item.fee }}</td>
                <td>{{ item.net }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-col cols="12" sm="3" order="-1" order-sm="2">
        <v-card min-height="300" min-width="150" elevation="0">
          <v-row class="pa-3">
            <v-col offset="1" cols="10" class="center relative">
              <v-avatar size="160">
                <v-img
                  class="card-img"
                  :src="
                    getFreelancerCompany.companyAvator !== undefined
                      ? getFreelancerCompany.companyAvator
                      : ''
                  "
                ></v-img>
              </v-avatar>
            </v-col>

            <v-col offset="1" cols="10" class="background">
              <v-row>
                <v-col>
                  <!------------------ PROFILE DATA ------------------>
                  <div
                    class="text-center mb-3 title text-secundario font-weight-bold"
                  >
                    <span>UGX</span
                    >{{
                      getFreelancerCompany.walletBalance !== undefined
                        ? getFreelancerCompany.walletBalance
                        : ""
                    }}
                  </div>
                  <div
                    class="text-center mb-3 title text-secundario font-weight-bold"
                  >
                    <span>@</span
                    >{{
                      getFreelancerCompany.companyName !== undefined
                        ? getFreelancerCompany.companyName
                        : ""
                    }}
                  </div>
                  <div
                    class="text-center mb-3 title text-secundario font-weight-bold"
                  >
                    <v-btn
                      class="ml-auto"
                      @click="dialogTopUp = true"
                      outlined
                      color="primary"
                      >TopUp</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-dialog v-model="dialogTopUp" width="500">
        <v-card>
          <v-tabs v-model="selectedGateWay" background-color="primary" dark>
            <v-tab v-for="gateWay in paymentGateways" :key="gateWay.id">
              {{ gateWay.text }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedGateWay">
            <!--Bank deposit-->
            <v-tab-item>
              <v-card flat>
                <v-card-text class="pa-5">
                  <v-form
                    ref="bankDepositForm"
                    v-model="bankValid"
                    lazy-validation
                  >
                    <v-text-field
                      v-model="bankDepositForm.gross"
                      label="Amount"
                      :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="bankDepositForm.bank"
                      label="Bank"
                      :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="bankDepositForm.recieptID"
                      label="Reciept ID"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
              </v-card>

              <v-card-actions class="pa-5">
                <v-btn
                  class="ml-auto"
                  @click="dialogTopUp = false"
                  outlined
                  color="primary"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  :disabled="!bankValid"
                  @click="validateBankDeposit"
                  >Send</v-btn
                >
              </v-card-actions>
            </v-tab-item>

            <!--Visa/master card-->
            <v-tab-item>
              <v-card flat>
                <v-card-text class="pa-5">
                  <v-form ref="cardForm" v-model="cardValid" lazy-validation>
                    <v-text-field
                      v-model="cardForm.gross"
                      label="Amount"
                      :rules="[rules.required]"
                    ></v-text-field>

                    <v-text-field
                      v-model="cardForm.cardNumber"
                      label="Card Number"
                      :rules="[rules.required]"
                    ></v-text-field>

                    <v-text-field
                      v-model="cardForm.cardName"
                      label="Card Name"
                      :rules="[rules.required]"
                    ></v-text-field>

                    <v-row>
                      <v-col lg="6">
                        <v-text-field
                          v-model="cardForm.expiryDate"
                          label="Expiry Date"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col lg="6">
                        <v-text-field
                          v-model="cardForm.csv"
                          label="CSV"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>

              <v-card-actions class="pa-5">
                <v-btn
                  class="ml-auto"
                  @click="dialogTopUp = false"
                  outlined
                  color="primary"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  :disabled="!cardValid"
                  @click="validateCardDeposit"
                  >Send</v-btn
                >
              </v-card-actions>
            </v-tab-item>

            <!--Mobile Money-->
            <v-tab-item>
              <v-card flat>
                <v-card-text class="pa-5">
                  <v-form
                    ref="mobileMoneyForm"
                    v-model="momoValid"
                    lazy-validation
                  >
                    <v-text-field
                      v-model="mobileMoneyForm.gross"
                      label="Amount"
                      :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="mobileMoneyForm.phoneNumber"
                      label="Phone Number"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
              </v-card>

              <v-card-actions class="pa-5">
                <v-btn
                  class="ml-auto"
                  @click="dialogTopUp = false"
                  outlined
                  color="primary"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  :disabled="!momoValid"
                  @click="validateMobileMoney"
                  >Send</v-btn
                >
              </v-card-actions>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import Vue from "vue";
import dayjs from "dayjs";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import {
  mdiMapMarker,
  mdiWeb,
  mdiCalendar,
  mdiPencil,
  mdiPencilOutline,
  mdiCameraRetakeOutline,
} from "@mdi/js";

export default {
  data: () => ({
    dialogTopUp: false,
    bankValid: false,
    cardValid: false,
    momoValid: false,
    bankDepositForm: {},
    cardForm: {},
    mobileMoneyForm: {},
    selectedGateWay: {},
    rules: {
      required: (value) => !!value || "This field is required",
      email: (v) => /.+@.+\..+/.test(v) || "Must be a valid email",
    },
    svg: {
      location: mdiMapMarker,
      web: mdiWeb,
      calendar: mdiCalendar,
      pencil: mdiPencil,
      camera: mdiCameraRetakeOutline,
    },

    headers: [
      {
        text: "Date",
        align: "left",
        sortable: false,
        value: "date",
      },
      { text: "Type", value: "type" },
      { text: "Status", value: "status" },
      { text: "Gross", value: "gross" },
      { text: "Fee", value: "fee" },
      { text: "Net", value: "net" },
    ],

    paymentGateways: [
      { text: "Bank Deposit ", value: "bankDeposit" },
      { text: "Visa/Master ", value: "card" },
      { text: "Mobile Money ", value: "mobileMoney" },
    ],
  }),

  beforeCreate() {
    this.$store.dispatch("fetchMyProfile");
    this.$store.dispatch("fetchMyCompany");
    this.$store.dispatch("fetchMyCompanyTransactions");
  },

  computed: {
    ...mapState(["myProfile", "freelancerCompany", "companyTransactions"]),
    ...mapGetters([
      "getMyProfile",
      "getFreelancerCompany",
      "getCompanyTransactions",
      "messageAlert",
    ]),
  },

  watch: {
    messageAlert(val) {
      if (val != null) {
        Vue.notify({
          group: "foo",
          title: val.title,
          text: val.body,
        });
        this.$store.dispatch("fetchMyProfile");
        this.$store.dispatch("fetchMyCompany");
        this.$store.dispatch("fetchMyCompanyTransactions");
      }
    },

    getFreelancerCompany(val) {
      if (val != null) {
        this.$store.dispatch("fetchMyProfile");
        this.$store.dispatch("fetchMyCompany");
        this.$store.dispatch("fetchMyCompanyTransactions");
      }
    },
  },

  filters: {
    exactDay(date) {
      return dayjs(date).format("h:mm a, MMMM DD YYYY");
    },
  },

  methods: {
    displayTransactionContextMenu(item) {
      alert(item.id);
    },

    validateBankDeposit() {
      const context = this;
      if (this.$refs.bankDepositForm.validate()) {
        // send it
        context.bankDepositForm.gateway = "BANK_DEPOSIT";
        context.$store.dispatch("topUpCredit", context.bankDepositForm);
        context.bankDepositForm = {};
        context.dialogTopUp = false;
      }
    },

    validateCardDeposit() {
      const context = this;
      if (this.$refs.cardForm.validate()) {
        // send it
        context.cardForm.gateway = "CARD";
        context.$store.dispatch("topUpCredit", context.cardForm);
        context.cardForm = {};
        context.dialogTopUp = false;
      }
    },

    validateMobileMoney() {
      const context = this;
      if (this.$refs.mobileMoneyForm.validate()) {
        // send it
        context.mobileMoneyForm.gateway = "MOBILE_MONEY";
        context.$store.dispatch("topUpCredit", context.mobileMoneyForm);
        context.mobileMoneyForm = {};
        context.dialogTopUp = false;
      }
    },
  },
};
</script>
